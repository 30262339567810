<template>
    <div class="system-container">
        <el-row>
            <!-- <el-button type="primary" size="small">查询</el-button> -->
            <el-button type="primary" size="small" @click="addGroup">新增分组</el-button>
        </el-row>
        <el-row style="margin-top: 20px;">
            <div>
                <label>信息类型：</label>
                <el-select v-model="infoType" size="mini" @change="searchCusdatacolumn">
                    <el-option 
                        v-for="item in infoTypeList" 
                        :key="item.id"
                        :value="item.id"
                        :label="item.name">
                    </el-option>
                </el-select>
            </div>
        </el-row>
        <el-row>
            <el-col :span="11" v-for="(field, index) in fieldGroupList" :key="index">
                <el-card style="margin:10px 5px"> 
                    <el-row>
                        <div class="data-title">
                            <span class="data-title-text">{{ field.title.name }}</span>
                            <!-- <el-tooltip class="item" effect="dark" content="删除" placement="bottom" style="float: right;">
                                <el-popconfirm
                                    title="确定删除该分组吗？"
                                    @onConfirm="delGroup(item)"
                                >
                                    <el-link  slot="reference" icon="el-icon-circle-close" :underline="false"></el-link>
                                </el-popconfirm>
                            </el-tooltip> -->
                        </div>
                    </el-row>
                    <el-row>
                        <el-table :data="field.list" border fit highlight-current-row class="fieldtable"
                            style="width: 100%"
                            :row-class-name="tableRowClassName"
                            @row-click="editData"
                            height="300">
                            <el-table-column align="center" label="序号" width="50">
                                <template slot-scope="scope">
                                    <span>{{scope.$index + 1}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column align="center" label="字段描述" width="100">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.fieldId !=0">{{scope.row.fieldLabel}}</span>
                                    <el-input v-else v-model="scope.row.fieldLabel"></el-input>
                                </template>
                            </el-table-column>
                            <el-table-column align="center" label="数据类型" width="100">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.fieldId !=0">{{scope.row.fieldTypeName}}</span>
                                    <el-select v-else v-model="scope.row.fieldType" size="mini">
                                        <el-option 
                                            v-for="item in fieldTypeList" 
                                            :key="item.id"
                                            :value="item.dict_Code"
                                            :label="item.type_Name">
                                        </el-option>
                                    </el-select>
                                </template>
                            </el-table-column>
                            <el-table-column align="center" label="数据源" width="100">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.fieldId !=0">{{scope.row.fieldDataCodeName}}</span>
                                    <el-select v-else-if="scope.row.fieldType=='select'" v-model="scope.row.fieldDataCode" size="mini">
                                        <el-option 
                                            v-for="item in dictionaryList" 
                                            :key="item.id"
                                            :value="item.code"
                                            :label="item.name">
                                        </el-option>
                                    </el-select>
                                </template>
                            </el-table-column>
                            <el-table-column align="center" label="必填" width="100">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.fieldId !=0|| scope.row.fieldType=='TEXT'">{{ scope.row.isMust==1?"必填":"非必填"}}</span>
                                    <el-select v-else v-model="scope.row.isMust" size="mini">
                                        <el-option 
                                            v-for="item in mustList" 
                                            :key="item.id"
                                            :value="item.id"
                                            :label="item.name">
                                        </el-option>
                                    </el-select>
                                </template>
                            </el-table-column>
                            <el-table-column align="left" label="操作"  width="210" :fixed="'right'">
                                <template slot-scope="scope">
                                    <el-button circle
                                        icon="el-icon-arrow-up"
                                        size="mini"
                                        :disabled="scope.$index===0"
                                        @click="moveUp(scope.$index,scope.row, field.list)"></el-button>
                                    <el-button circle
                                        icon="el-icon-arrow-down"
                                        size="mini"
                                        :disabled="scope.$index===(field.list.length-1)"
                                        @click="moveDown(scope.$index,scope.row, field.list)"></el-button>
                                    <el-button circle icon="el-icon-delete" size="mini" type="danger" @click="removeData(scope.row, scope.$index, field.list)" v-show="field.list.length>1">
                                    </el-button>
                                    <el-button circle size="mini" icon="el-icon-folder" type="primary" plain @click="saveData(scope.row)" v-show="scope.row.fieldId ==0">
                                    </el-button>
                                    <el-button circle icon="el-icon-plus" size="mini" type="primary" @click="addData(scope.$index, field)" v-show="scope.$index===(field.list.length-1)">
                                    </el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-row>
                </el-card>
            </el-col>
        </el-row>
        <el-dialog
            title="新增分组"
            :visible.sync="isNew"
            width="30%"
            >
            <el-input v-model="newTitle"></el-input>
            <span slot="footer" class="dialog-footer">
                <el-button @click="isNew = false">取 消</el-button>
                <el-button type="primary" @click="insertDataGroup">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import api from "@/api/index.js";
import constants from "@/constants/Map.constants.js";
const gap = 100;
const INFOTYPELIST = [ // 信息类型下拉数据
    {
        id: 1,
        name: '机会详情'
    },
    {
        id: 2,
        name: '门店详情'
    },
    {
        id: 3,
        name: '商圈详情'
    },
];
const MUSTLIST = [
    {
        id: 0,
        name: '非必填'
    },
    {
        id: 1,
        name: '必填'
    }
];
const TYPEID = 143;
export default {
    name: "dataMaintain",
    data(){
        return {
            options: {
                page: 1,
                size: 10,
                parameter: "",
                cusNo: ""
            },
            editDataIndex: -1,
            typeCode: "",
            cusNo: "",
            infoType: 1, // 信息类型
            infoTypeList: INFOTYPELIST, // 信息类型下拉数据
            mustList: MUSTLIST, // 必填字段下拉数据
            fieldGroupList: [], // 分组数据列表
            detaillist: [], // 新增分组
            fieldTypeList: [], // 数据类型下拉
            dictionaryList: [], // 数据源下拉
            newTitle: "", // 新增分组标题
            isNew: false, // 是否在新增
        }
    },
    created(){
        this.options.cusNo = sessionStorage.cusNo;
        this.cusNo = sessionStorage.cusNo;
        this.searchCusdatacolumn(this.infoType); // 获取分组信息
        this.dataTypeByTypeID(TYPEID); // 获取数据类型
        this.searchCusdictionary(); // 获取数据源
    },
    methods: {
        // 数据字典-TypeID 143
        dataTypeByTypeID( typeID ) {
            api.dataTypeByTypeID({
                typeID
            }).then( res => {
                if( res.code == 200 && res.data.length > 0 ) {
                    this.fieldTypeList = res.data;
                }
            });
        },
        // 获取数据源
        searchCusdictionary() {
            api.searchCusdictionary({
                cusNo: this.cusNo
            }).then( res => {
                if( res.code == 200 ) {
                    this.dictionaryList = res.data.data;
                }
            })
        },
        // 获取分组信息
        searchCusdatacolumn() {
            api.searchCusdatacolumn({
                cusNo: this.cusNo,
                dataType: this.infoType
            }).then( res => {
                if(res.code == 200) {
                    this.fieldGroupList = res.data.data.map( item => {
                        item.list.sort(( a, b ) => {
                            return a.sortNo - b.sortNo;
                        });
                        return item;
                    });
                }
            });
        },
        
        //向上移动
        moveUp(index, row, field) {
            let type = "moveUp";
            if (index > 0) {
                api.updateCusdatacolumnSortByFieldId({
                    fieldId: row.fieldId,
                    groupId: row.groupId,
                    sortNo: row.sortNo,
                    type
                }).then(res => {
                    if(res.code == 200) {
                        let upDate = field[index - 1];
                        field.splice(index - 1, 1);
                        field.splice(index, 0, upDate);
                    } else {
                        this.$message.error("上移位置失败");
                    }
                });
            } else {
                this.$message({
                    type: "warning",
                    message: "已经是第一条，不可上移"
                });
            }
        },
        //向下移动
        moveDown(index, row, field) {
            let type = "moveDown";
            if ((index + 1) === field.length) {
                this.$message({
                    type: "warning",
                    message: "已经是最后一条，不可下移"
                });
            } else {
                api.updateCusdatacolumnSortByFieldId({
                    fieldId: row.fieldId,
                    groupId: row.groupId,
                    sortNo: row.sortNo,
                    type
                }).then(res => {
                    if(res.code == 200) {
                        let downDate = field[index + 1];
                        field.splice(index + 1, 1);
                        field.splice(index, 0, downDate);
                    } else {
                        this.$message.error("下移位置失败");
                    }
                });
                
            }
        },
        //新增数据项明细行
        addData(index, field) {
            field.list.push({
                cusNo: this.cusNo,
                dataType: field.title.dataType,
                fieldId: 0,
                fieldLabel: "",
                fieldName: "",
                fieldType: "",
                fieldTypeName: "-",
                fieldDataCode: "",
                fieldDataName: "",
                groupId: field.title.groupId,
                name: field.title.name,
                sortNo: 0,
                creator: sessionStorage.userName,
                isMust: 0
            });
        },
        //移除
        removeData(item, index, field) {
            if(field.length <= 1) {
                field = [{code: ""}];
            } else {
                if(item.id == 0) {
                    field.splice(index, 1);
                } else {
                    api.deleteCusdatacolumn({
                        fieldId: item.fieldId
                    }).then(res => {
                        if(res.code == 200) {
                            field.splice(index, 1);
                        } else {
                            this.$message.error("删除失败");
                        }
                    });
                }
            }
        },
        //保存数据明细
        saveData(item) {
            if(true) {
                let ops = {
                    creator: item.creator,
                    dataType: item.dataType,
                    fieldDataCode: item.fieldDataCode,
                    fieldLabel: item.fieldLabel,
                    fieldType: item.fieldType,
                    groupId: item.groupId,
                    isMust: item.isMust
                }
                api.insertCusdatacolumn(ops).then(res => {
                    if(res.code == 200) {
                        this.searchCusdatacolumn(this.infoType); 
                    }
                });
            } else {
                this.$message({
                    type: "warning",
                    message: "保存数据不能为空！"
                });
            }
        },
        tableRowClassName ({row, rowIndex}) {
           //把每一行的索引放进row
           row.index = rowIndex;
           
        },
        editData(row, event, column) {
            console.log(row, event, column);
            this.editDataIndex = row.index;
        },
        // 新增分组
        addGroup() {
            if(this.isNew == false) {
                this.isNew = true;
            }
        },
        // 删除分组
        delGroup(item) {

        },
        // 保存新增分组
        insertDataGroup() {
            let opt = {
                code: "",
                creator: sessionStorage.userName,
                cusNo: this.cusNo,
                dataType: this.infoType,
                name: this.newTitle
            };
            api.insertDataGroup(opt).then( res => {
                if(res.code == 200) {
                    this.searchCusdatacolumn(this.infoType);
                    this.isNew = false;
                }
            })
        }
    }
}
</script>
<style>
.data-title {
    border-left: 4px solid #0188e2;
    padding-left: 30px;
    margin-bottom: 10px;
    height: 20px;
}
.data-title-text {
    font-size: 14px;
}
.data-tag {
    margin-top: 30px;
}
.data-tag .el-tag {
    cursor: pointer;
}
.data-tag .el-tag + .el-tag {
    margin-left: 10px;
}
.data-tag .button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
}
.data-tag .input-new-tag {
    width: 90px;
    margin-left: 10px;
    vertical-align: bottom;
}

.system-container {
    font-size: 13px;
    padding: 10px 30px;
}
.system-text {
    font-size: 13px;
}
.el-dropdown-link1 {
    cursor: pointer;
    color: #409EFF;
    font-size: 12px;
  }
  .el-icon-arrow-down {
    font-size: 12px;
  }
  .system-container .number {
      float: left;
  }
  .system-container .more {
      float: left;
  }
  .fieldtable .el-input .el-input__inner{
    height: 28px;
    line-height: 28px;
 }
</style>
